// Dependent colors
$black: #000000;
$white: #ffffff;

$classic-base-color: #2e2e2e;
$classic-primary-color: #d7aea3;
$classic-secondary-color: #e1e1e1;
$classic-highlight-color: #d9742b;

$ui-base-color: $classic-base-color !default;
$ui-primary-color: $classic-primary-color !default;
$ui-secondary-color: $classic-secondary-color !default;

$blurple-600: #bf682a;
$blurple-500: #d9742b;
$blurple-400: #d98448;
$blurple-300: #e09865;
$grey-600: #5a4e4c;
$grey-100: #f3dada;

// Differences
$ui-highlight-color: $blurple-500 !default;

$ui-button-secondary-color: $grey-600 !default;
$ui-button-secondary-border-color: $grey-600 !default;
$ui-button-secondary-focus-color: $white !default;

$ui-button-tertiary-color: $blurple-500 !default;
$ui-button-tertiary-border-color: $blurple-500 !default;

$darker-text-color: #bebebe !default;
$dark-text-color: #7e7e7e !default;
$secondary-text-color: lighten($ui-secondary-color, 6%) !default;
$highlight-text-color: $classic-highlight-color !default;
$action-button-color: #7f6954;

$inverted-text-color: $black !default;
$lighter-text-color: darken($ui-base-color,6%) !default;
$light-text-color: darken($ui-primary-color, 40%) !default;
